@import './../../../../scss/theme-bootstrap';

.basic-carousel-formatter {
  text-align: center;
  position: relative;
  .carousel-controls {
    top: 33%;
    @include breakpoint($medium-up) {
      top: 27%;
    }
    @include breakpoint($landscape-up) {
      top: 31%;
    }
    @include breakpoint($large-up) {
      top: 37%;
    }
    .slick-prev {
      color: $color-dark-gray-arrow;
      #{$ldirection}: -20px;
    }
    .slick-next {
      color: $color-dark-gray-arrow;
      #{$rdirection}: -20px;
    }
  }
  &--margin {
    margin: 60px 0;
    @include breakpoint($landscape-up) {
      margin: 110px 0;
    }
  }
  .basic-carousel {
    &__slide {
      padding: 30px;
    }
  }
  .basic-carousel {
    &__title {
      width: 100%;
      @include breakpoint($landscape-up) {
        width: 45%;
      }
      margin: 0 auto;
      position: relative;
      @include h2-bare;
      &-rule {
        border-top: 1px solid $color-darker-gray-hr;
        color: $color-grey;
        position: absolute;
        top: -0.5em;
        width: 100%;
        z-index: 1;
      }
      &-text {
        background: $color-white;
        z-index: 300;
        position: relative;
        display: inline-block;
        padding: 0 15px;
        @include breakpoint($landscape-up) {
          padding: 0 20px;
        }
      }
    }
    &__subtitle {
      margin-top: 15px;
      font-size: 13px;
      padding: 0px 25px;
      margin-bottom: 15px;
      @include breakpoint($landscape-up) {
        margin-bottom: 0px;
        font-size: 16px;
      }
    }
    &__button {
      @include button-secondary;
      margin-bottom: 0px;
      @include breakpoint($landscape-up) {
        margin-bottom: 30px;
      }
    }
  }
}

// perfume storeis
$perfume-stories-slide-padding: 35px;
$perfume-stories-slide-max-width: 472px + $perfume-stories-slide-padding * 2;

.basic-carousel-formatter.perfume-stories-carousel {
  margin: 0px;
  background: $color-perfume-stories-gray;
  padding: 60px 0;
  @include breakpoint($landscape-up) {
    background: $color-white;
    padding: 180px 0;
  }
  .basic-carousel-formatter {
    &__inner {
      width: 90%;
      max-width: $perfume-stories-slide-max-width * 3;
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
    }
  }
  .basic-carousel {
    &__title {
      &-text {
        background: $color-perfume-stories-gray;
        @include breakpoint($landscape-up) {
          background: $color-white;
        }
      }
    }
    .carousel-dots {
      position: absolute;
      bottom: 98px;
      z-index: 2;
      @include breakpoint($medium-up) {
        bottom: 165px;
      }
    }
    &__header {
      @include breakpoint($landscape-up) {
        margin-bottom: 50px;
      }
    }
    &__slide {
      padding: 0px;
      @include breakpoint($landscape-up) {
        padding: 0px $perfume-stories-slide-padding;
        // 471 is max image width
        max-width: $perfume-stories-slide-max-width;
      }
      img {
        max-height: 383px;
        max-width: 236px;
        @include breakpoint($medium-up) {
          max-width: 290px;
        }
        @include breakpoint($landscape-up) {
          max-width: 100%;
        }
      }
    }
    &__button {
      margin-top: 5px;
      background: $color-perfume-stories-gray;
      color: $color-black;
      @include breakpoint($landscape-up) {
        @include button-secondary;
        margin-top: 60px;
      }
    }
    .carousel-controls {
      .slick-prev {
        @include breakpoint($medium-up) {
          #{$ldirection}: -25px;
        }
        @include breakpoint($landscape-up) {
          #{$ldirection}: -35px;
        }
      }
      .slick-next {
        @include breakpoint($medium-up) {
          #{$rdirection}: -25px;
        }
        @include breakpoint($landscape-up) {
          #{$rdirection}: -35px;
        }
      }
    }
  }
  .content-block-carousel-slide {
    &__title {
      text-transform: uppercase;
      margin-top: 70px;
      @include breakpoint($medium-up) {
        margin-top: 0px;
      }
      @include breakpoint($landscape-up) {
        display: block;
        font-size: 16px;
        font-weight: 500;
      }
    }
    &__subtitle {
      @include breakpoint($landscape-up) {
        display: block;
      }
    }
  }
}
